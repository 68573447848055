import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import {
  CheckBrowser,
  RequestPermission,
  WebMessaging,
  NativeMessaging,
  SaveDeviceToken,
  SubscribeToPlace,
  SusbcribeToBill,
  SuscribeToNotification,
} from "./actions/device.actions";
import { Plugins, PushNotificationToken } from "@capacitor/core";
import { patch } from "@ngxs/store/operators";
import { ApiService } from "../services/api.service";
import { tap } from "rxjs/operators";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { trace } from "@angular/fire/compat/performance";
const { Device, StatusBar, PushNotifications } = Plugins;
import { isSupported } from "firebase/messaging";
import { UserDevice } from "../interface/device";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import firebase from "firebase/compat/app";

@State<UserDevice>({
  name: "device",
  defaults: null,
})
@Injectable()
export class UserDeviceState {
  constructor(
    private api: ApiService,
    private store: Store,
    private afMessaging: AngularFireMessaging,
    private analytics: AngularFireAnalytics
  ) {}

  @Selector()
  static uuid({ uuid }: UserDevice) {
    return uuid;
  }

  @Selector()
  static PushSupport({ PushSupport }: UserDevice) {
    return PushSupport;
  }

  @Selector()
  static State_NotificationDevice({
    Notification: { State_NotificationDevice },
  }: UserDevice) {
    return State_NotificationDevice;
  }

  ngxsOnInit({ dispatch }: StateContext<UserDevice>) {
    dispatch(new CheckBrowser());
  }

  @Action(CheckBrowser)
  async CheckBrowser({ setState }: StateContext<UserDevice>) {
    const deviceInfo = await Device.getInfo();
    const PushSupport =
      deviceInfo.platform === "ios" ||
      deviceInfo.platform === "android" ||
      isSupported();
    const {
      appBuild: AppBuild,
      appId: AppId,
      appVersion: AppVersion,
      manufacturer: Manufacturer,
      model: Model,
      operatingSystem: OperatingSystem,
      osVersion: OsVersion,
      platform: Platform,
      uuid: uuid,
    } = deviceInfo;
    const {
      data: { device },
    } = await this.api
      .updateDevice({
        AppBuild,
        AppId,
        AppVersion,
        Manufacturer,
        Model,
        OperatingSystem,
        OsVersion,
        Platform,
        uuid,
        FK_Module: 10,
      })
      .toPromise();
    setState({ ...device, PushSupport });
    if (
      deviceInfo.platform === "ios" ||
      deviceInfo.platform === "android" ||
      deviceInfo.platform !== "web"
    ) {
      StatusBar.hide();
    }
  }

  @Action(RequestPermission)
  requestPermission(
    { getState, dispatch }: StateContext<UserDevice>,
    { bill }: RequestPermission
  ) {
    const { Platform } = getState();
    switch (Platform) {
      case "web":
        dispatch(new WebMessaging(bill));
        break;
      case "android":
      case "ios":
        dispatch(new NativeMessaging(bill));
        break;
    }
  }

  @Action(WebMessaging)
  WebMessaging(
    { dispatch, setState }: StateContext<UserDevice>,
    { bill }: WebMessaging
  ) {
    return this.afMessaging.requestToken.pipe(
      tap((Token) => {
        dispatch(new SaveDeviceToken(Token, bill));
      }),
      trace("SusbcribeToFCMWeb")
    );
  }

  @Action(NativeMessaging)
  async NativeMessaging(
    { dispatch }: StateContext<UserDevice>,
    { bill }: NativeMessaging
  ) {
    const result = await PushNotifications.requestPermission();
    if (result.granted) {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
    } else {
      // log error
    }
    let registrationError;
    const registration = PushNotifications.addListener(
      "registration",
      (token: PushNotificationToken) => {
        if (registration) {
          registration.remove();
        }
        if (registrationError) {
          registrationError.remove();
        }
        dispatch(new SaveDeviceToken(token.value, bill));
      }
    );
    registrationError = PushNotifications.addListener(
      "registrationError",
      (error: any) => {
        if (registration) {
          registration.remove();
        }
        if (registrationError) {
          registrationError.remove();
        }
        // log error
      }
    );
  }

  @Action(SaveDeviceToken)
  SaveDeviceToken(
    { getState, setState }: StateContext<UserDevice>,
    { token, bill }: SaveDeviceToken
  ) {
    const jwt = this.store.selectSnapshot((s) => s.user.token);
    const { uuid } = getState();
    return this.api.saveDeviceToken({ token: jwt, uuid }, token).pipe(
      tap((data) => {
        setState(patch({ Token: token }));
        if (bill) {
          this.store.dispatch(new SusbcribeToBill(token, bill));
        } else {
          this.store.dispatch(new SuscribeToNotification(token));
        }
      })
    );
  }

  @Action(SubscribeToPlace)
  SubscribeToPlace(
    { getState, setState }: StateContext<UserDevice>,
    { token, FK_Place }: SubscribeToPlace
  ) {
    const jwt = this.store.selectSnapshot((s) => s.user.token);
    const { uuid } = getState();
    return this.api.subscribeToPlace({ token: jwt, uuid }, FK_Place).pipe(
      tap((data) => {
        setState(patch({ Token: token }));
      })
    );
  }

  @Action(SusbcribeToBill)
  SusbcribeToBill(
    { setState, getState }: StateContext<UserDevice>,
    { token, bill }: SusbcribeToBill
  ) {
    const { Notification } = getState();
    const infoDevice = this.buildInfoDevice(Notification, token);
    return this.api.suscribeToBill(infoDevice).pipe(
      tap(({ data: { notificationDevice } }) => {
        if (notificationDevice) {
          setState(patch({ Notification: notificationDevice }));
          this.analytics.logEvent("SusbcribeToBill");
        }
      }),
      trace("SusbcribeToBill")
    );
  }

  @Action(SuscribeToNotification)
  suscribeToNotification(
    { setState, getState }: StateContext<UserDevice>,
    { Token_NotificationDevice }: SuscribeToNotification
  ) {
    const jwt = this.store.selectSnapshot((s) => s.user.token);
    const { Notification } = getState();
    const infoDevice = this.buildInfoDevice(
      Notification,
      Token_NotificationDevice
    );
    return this.api.suscribeToNotification(infoDevice).pipe(
      tap(({ data: { notificationDevice } }) => {
        setState(patch({ Notification: notificationDevice }));
        this.analytics.logEvent("SuscribeToNotification");
      }),
      trace("SuscribeToNotification")
    );
  }

  buildInfoDevice = (Notification, Token_NotificationDevice) =>
    Notification
      ? {
          id: Notification.id,
          State_NotificationDevice: !Notification.State_NotificationDevice,
          Token_NotificationDevice,
        }
      : {
          State_NotificationDevice: true,
          Token_NotificationDevice,
        };
}
